import {
  CatalogPageTypes,
  CourseModel,
  dateService,
  isPhoneValid,
  Nullable,
  phoneRegex,
  PromoCodeErrorModel,
  PromoCodeWithErrorModel,
  setAcceptLanguage,
  stringService,
  Translations,
  utmService,
} from '@lerna-core';
import * as Yup from 'yup';
import {
  GuestOrderFormInitialValues,
  GuestOrderFormValidationSchema,
} from './guestOrderForm.model';
import { getTextMaxLengthValidationMessage } from '../form.validations';
import {
  B2CFreeOrderResponse,
  B2COrderRequestParams,
  B2COrderResponse,
  FreeOrderRequestParams,
} from '../form.model';
import {
  b2cOrderClientProvider,
  discountProgramOrderClientProvider,
  freeOrderClientProvider,
} from '../form.clientProvider';
import { NextRouter } from 'next/router';

export const sendGuestOrderRequest = (
  router: NextRouter,
  nameValue: string,
  emailValue: string,
  phoneValue: string,
  course: CourseModel,
  courseUrl: Nullable<string>,
  pageName: Nullable<string>,
  locale?: string,
  promoCode?: string,
  company?: string,
  discountProgram?: string
): Promise<Nullable<B2COrderResponse | B2CFreeOrderResponse>> => {
  const userTimezone = dateService.UTCTimezone();
  const headers = setAcceptLanguage(locale);
  const utmQuery = utmService.getQueryStringFromCookie();
  const utmObj =
    utmService.getUTMFromQuery(router.query, false) ||
    utmService.getUTMFromCookie(undefined, false);
  const requestParams: B2COrderRequestParams = {
    user_name: nameValue,
    email: emailValue,
    phone: phoneValue,
    course_slug: course.slug,
    course_url: courseUrl,
    is_data_agreement_accepted: true,
    price: {
      amount: String(
        parseInt(stringService.replaceSpaces(course.price.price.amount))
      ),
      currency: course.price.price.currency,
    },
    start_date: !!course.startDateRaw ? course.startDateRaw : null,
    lang: locale,
    is_request_from_catalog: pageName === CatalogPageTypes.courses,
  };
  if (promoCode) {
    requestParams.promo_code = promoCode;
  }
  if (company) {
    requestParams.company = company;
  }
  if (userTimezone) {
    requestParams.user_utc_time_zone = userTimezone;
  }
  if (utmQuery) {
    requestParams.utmQuery = utmQuery;
  }

  //send request for course with startPrice=0
  if (course.isFree && !discountProgram) {
    const freeOrderRequestParams: FreeOrderRequestParams = {
      course_id: course.uuid,
      customer: {
        name: nameValue,
        email: emailValue,
        phone: phoneValue,
      },
      referer_url: `${window?.location?.origin}${window?.location?.pathname}`,
      is_data_agreement_accepted: true,
      ...(utmObj && { utm: utmObj }),
    };

    return freeOrderClientProvider(freeOrderRequestParams, headers);
  }
  if (discountProgram) {
    requestParams.discount_program = discountProgram;

    return discountProgramOrderClientProvider(requestParams, headers);
  }

  return b2cOrderClientProvider(requestParams, headers);
};

export const getGuestOrderFormInitialValues =
  (): GuestOrderFormInitialValues => ({
    name: '',
    email: '',
    phone: '',
    withCompany: false,
    company: '',
    withPromoCode: false,
    promoCode: '',
    promoCodeDraft: '',
    privacy: false,
  });

export function getGuestOrderFormValidationSchema(
  translations: Translations,
  promoCode: Nullable<PromoCodeWithErrorModel>,
  isRuRegion: boolean,
  discountProgramQuery?: string
): GuestOrderFormValidationSchema {
  const validationObject = {
    name: Yup.string()
      .required(translations?.input_required_error_label)
      .max(255, getTextMaxLengthValidationMessage(255, translations))
      .trim(),
    email: Yup.string()
      .email(translations?.input_email_format_error_label)
      .required(translations?.input_required_error_label)
      .trim(),
    phone: Yup.string()
      .required(translations?.input_required_error_label)
      .matches(phoneRegex, translations?.input_phone_format_error_label)
      .test(
        'phone',
        translations?.input_phone_format_error_label,
        isPhoneValid
      ),
    withCompany: Yup.boolean().optional(),
    company: Yup.string().when('withCompany', {
      is: true,
      then: (company) =>
        company
          .required(translations?.input_required_error_label)
          .max(255, getTextMaxLengthValidationMessage(255, translations))
          .trim(),
    }),
    withPromoCode: Yup.boolean().optional(),
  };

  if (!isRuRegion) {
    Object.assign(validationObject, {
      privacy: Yup.boolean()
        .required()
        .oneOf([true], translations?.input_required_error_label),
    });
  }

  if (!!discountProgramQuery) {
    Object.assign(validationObject, {
      promoCodeDraft: Yup.string()
        .when('withPromoCode', {
          is: true,
          then: (code) =>
            code
              .required(
                translations?.promo_code_is_not_applied ||
                  'Промокод не применён'
              )
              .max(255, getTextMaxLengthValidationMessage(255, translations))
              .trim(),
        })
        .test(
          'promoCode status 404',
          'Промокод не подходит',
          () => !((promoCode as PromoCodeErrorModel)?.status === 404)
        )
        .test(
          'promoCode status 410',
          'Истек срок действия промокода',
          () => !((promoCode as PromoCodeErrorModel)?.status === 410)
        ),
    });
  } else {
    Object.assign(validationObject, {
      promoCode: Yup.string()
        .when('withPromoCode', {
          is: true,
          then: (code) =>
            code
              .required(translations?.input_required_error_label)
              .max(255, getTextMaxLengthValidationMessage(255, translations))
              .trim(),
        })
        .test(
          'promoCode status 404',
          'Промокод не подходит',
          () => !((promoCode as PromoCodeErrorModel)?.status === 404)
        )
        .test(
          'promoCode status 410',
          'Истек срок действия промокода',
          () => !((promoCode as PromoCodeErrorModel)?.status === 410)
        ),
    });
  }

  return Yup.object().shape(validationObject).required();
}
